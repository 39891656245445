import React from "react";
import { Link } from "react-router-dom";
import { Treatment } from "../../../types";
import Badge from "../../Badge/Badge";

type TreatmentCardProps = {
	treatment: Treatment;
	className?: string;
};

const TreatmentCard = React.forwardRef<HTMLAnchorElement, TreatmentCardProps>(
	({ treatment, className }, ref) => {
		const { name, speciality, tagline, popular_countries } = treatment;

		return (
			<Link
				ref={ref}
				to={"/treatments/" + treatment.id}
				className={`card card--interactive treatment-card ${className || ""}`}
			>
				<span className="treatment-card__top">
					<Badge color={speciality?.color}>{speciality?.name}</Badge>
					<span className="h4">{name}</span>
					<span className="paragraph--sm text--secondary">{tagline}</span>
				</span>
				<span className="treatment-card__bottom">
					{popular_countries && popular_countries.length > 0 && (
						<span className="paragraph--sm">Popular in</span>
					)}
					<span className="treatment-card__countries">
						{popular_countries?.map((country, index) => (
							<span key={index} className="tag tag--sm">
								{country.name}
							</span>
						))}
					</span>
				</span>
			</Link>
		);
	}
);

export default TreatmentCard;
