import React from "react";
import * as Icon from "react-feather";
import { Clinic, Review } from "../../../types";
import Badge from "../../Badge/Badge";

type ReviewCardProps = {
	review: Review;
	clinic?: Clinic;
};

const ReviewCard: React.FC<ReviewCardProps> = ({ review, clinic }) => {
	const { rating, name, text, source, treatment } = review;

	// Function to render stars
	const renderStars = (rating: number) => {
		const stars = [];
		for (let i = 1; i <= 5; i++) {
			stars.push(
				<Icon.Star
					key={i}
					size={16}
					className={`star ${i <= rating ? "star--filled" : ""}`}
				/>
			);
		}
		return stars;
	};

	return (
		<div className="review-card">
			<button className="review-card__interactive card card--interactive">
				<span className="review-card__header">
					<span className="review-card__stars">
						{renderStars(Math.round(rating))}
					</span>
					<span className="paragraph--xs text--secondary">
						via {source.name}
					</span>
				</span>
				<span className="review-card__text">{text}</span>
				<span className="review-card__name paragraph--sm text--semibold">
					{name}
				</span>
				<span className="review-card__footer paragraph--sm text--secondary">
					<Badge color={treatment.speciality?.color}>{treatment.name}</Badge>
					{clinic && <span>at {clinic.name}</span>}
				</span>
			</button>
		</div>
	);
};

export default ReviewCard;
