import React, { useState } from "react";
import * as Icon from "react-feather";
import Badge from "../../components/Badge/Badge";
import { Clinic, Treatment } from "../../types";
import ClinicMap from "../ClinicMap/ClinicMap";
import { useGlobal } from "../../contexts/GlobalContext";
import { getTreatmentById } from "../../util/clinicUtil";

type ClinicInfoPanelProps = {
	clinic: Clinic;
	treatment?: Treatment | null;
};

const ClinicInfoPanel: React.FC<ClinicInfoPanelProps> = ({
	clinic,
	treatment,
}) => {
	const [selectedTreatment, setSelectedTreatment] = useState<Treatment | null>(
		treatment || null
	);
	const { treatments, isLoading } = useGlobal();

	// Group treatments by speciality
	const groupedTreatments =
		clinic.treatments?.reduce((groups, t) => {
			const specialityName =
				getTreatmentById(t.treatment, treatments)?.speciality?.name || "Other";
			if (!groups[specialityName]) groups[specialityName] = [];
			groups[specialityName].push(t);
			return groups;
		}, {} as Record<string, typeof clinic.treatments>) || {};

	return (
		<aside className="clinic__panel card card--floating">
			<ClinicMap clinic={clinic} />
			{!clinic.treatments ||
				(clinic.treatments.length === 0 && (
					<p className="text--secondary text--centered margin-left--auto margin-top--xxxl margin-bottom--xxxl">
						No treatments available at this clinic.
					</p>
				))}

			<ul className="clinic__treatments list">
				{isLoading && (
					<div className="list__skeleton">
						<div className="list__item list__item__button skeleton-box"></div>
						<div className="list__item list__item__button skeleton-box"></div>
						<div className="list__item list__item__button skeleton-box"></div>
						<div className="list__item list__item__button skeleton-box"></div>
					</div>
				)}

				{!isLoading && selectedTreatment && (
					<>
						<li className="list__divider paragraph--sm text--medium">
							Selected treatment
						</li>
						<li>
							<button
								className="clinic__treatments__item list__item__button"
								onClick={() => setSelectedTreatment(null)}
							>
								<Badge color={selectedTreatment?.speciality?.color}>
									{selectedTreatment?.name}
								</Badge>
								<Icon.ChevronDown size={20} className="text--secondary" />
							</button>
						</li>
						<li className="list__divider paragraph--sm text--medium">
							Treatment duration
						</li>
						<li className="clinic__treatment__info">
							<span>Approx. treatment duration</span>
							<span className="text--secondary">
								{clinic.treatments?.find(
									(t) => t.treatment === selectedTreatment?.id
								)?.duration_hours + " hours" || "Not specified"}
							</span>
						</li>
						<li className="clinic__treatment__info">
							<span>Approx. recovery period</span>
							<span className="text--secondary">
								{clinic.treatments?.find(
									(t) => t.treatment === selectedTreatment?.id
								)?.recovery_days + " days" || "Not specified"}
							</span>
						</li>
						<li className="clinic__treatment__price">
							{clinic.treatments?.find(
								(t) => t.treatment === selectedTreatment?.id
							)?.starting_price !== undefined ? (
								<>
									<p className="paragraph--sm text--secondary">
										Prices starting at
									</p>
									<h5 className="clinic__treatment__price__value">
										$
										{clinic.treatments
											?.find((t) => t.treatment === selectedTreatment?.id)
											?.starting_price?.toFixed(2)}
									</h5>
									<button className="button button--primary">
										See booking options
									</button>
								</>
							) : (
								<p className="paragraph--sm text--secondary">
									Price not available
								</p>
							)}
						</li>
					</>
				)}

				{!isLoading &&
					!selectedTreatment &&
					Object.entries(groupedTreatments).map(
						([speciality, clinicTreatments]) => (
							<li key={speciality} className="clinic__treatments__group">
								<p className="list__divider paragraph--sm text--medium">
									{speciality}
								</p>
								<ul className="list">
									{clinicTreatments.map((treatment) => (
										<li key={treatment.id} className="list__item ">
											<button
												className="clinic__treatments__item list__item__button"
												onClick={() =>
													setSelectedTreatment(
														getTreatmentById(treatment.treatment, treatments)
													)
												}
											>
												<Badge
													color={
														getTreatmentById(treatment.treatment, treatments)
															.speciality?.color
													}
												>
													{
														getTreatmentById(treatment.treatment, treatments)
															.name
													}
												</Badge>
												{treatment.starting_price !== undefined && (
													<span className="text--secondary">
														From ${treatment.starting_price.toFixed(2)}
													</span>
												)}
											</button>
										</li>
									))}
								</ul>
							</li>
						)
					)}
			</ul>
		</aside>
	);
};

export default ClinicInfoPanel;
