import { Link } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import DestinationsTeaser from "../../components/DestinationsTeaser/DestinationsTeaser";
import Faq from "../../components/Faq/Faq";
import Footer from "../../components/Footer/Footer";
import Hero from "../../components/Hero/Hero";
import Navigation from "../../components/Navigation/Navigation";
import TreatmentsTeaser from "../../components/TreatmentsTeaser/TreatmentsTeaser";
import ValueProposition from "../../components/ValueProposition/ValueProposition";
import banner from "../../images/banner-travel.jpg";
import background from "../../images/hero-home.jpg";

export const Home = (): React.ReactElement => {
	return (
		<div className="home clinic">
			<Navigation isDarkBackground />

			<Hero isFullHeight className="home__hero" image={background}>
				<h1>The Right Care, Anywhere</h1>
				<Link to="/clinics" className="button button--floating">
					Discover clinics
				</Link>
				{/* <Search /> */}
			</Hero>

			<TreatmentsTeaser />

			<Banner
				title="Affordable Care, Global Expertise"
				body="Medical tourism offers patients access to world-class treatments at more affordable prices, often in renowned healthcare destinations. Whether you’re seeking specialized surgeries, cosmetic procedures, or routine care, traveling abroad can provide greater options and lower costs without compromising quality."
				image={banner}
			/>

			<DestinationsTeaser />

			<ValueProposition />

			<Faq />

			<Footer />
		</div>
	);
};
