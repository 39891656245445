import React from "react";
import * as Icon from "react-feather";
import { useComparison } from "../../contexts/ComparisonContext";
import { Clinic } from "../../types";

type CompareButtonProps = {
	clinic: Clinic;
	className?: string;
};

const CompareButton: React.FC<CompareButtonProps> = ({ clinic, className }) => {
	const { comparisonCart, addToCart } = useComparison();
	const isInCart = comparisonCart.some((c) => c.id === clinic.id);

	return (
		<button
			className={`button button--sm ${className ? className : ""} ${
				isInCart ? "button--inverted" : ""
			}`}
			onClick={() => addToCart(clinic)}
		>
			{isInCart ? (
				<>
					<Icon.Check size={20} />
					Comparing
				</>
			) : (
				<>
					<Icon.Columns size={20} />
					Compare
				</>
			)}
		</button>
	);
};

export default CompareButton;
