import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useParams, useSearchParams } from "react-router-dom";
import ReviewCard from "../../components/Cards/ReviewCard/ReviewCard";
import ClinicInfoPanel from "../../components/ClinicInfoPanel/ClinicInfoPanel";
import ClinicToolbar from "../../components/ClinicToolbar/ClinicToolbar";
import Footer from "../../components/Footer/Footer";
import ImageGrid from "../../components/ImageGrid/ImageGrid";
import Navigation from "../../components/Navigation/Navigation";
import Rating from "../../components/Rating/Rating";
import { useGlobal } from "../../contexts/GlobalContext";
import { Clinic, Treatment } from "../../types";
import { getClinic } from "../../api/api";
import ClinicDetailSkeleton from "../../components/ClinicDetailSkeleton/ClinicDetailSkeleton";

const ClinicDetail = (): React.ReactElement => {
	const [clinic, setClinic] = useState<Clinic | null>(null);
	const [treatment, setTreatment] = useState<Treatment | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const { treatments } = useGlobal();
	const { id } = useParams();
	const [searchParams] = useSearchParams();
	const treatmentId = searchParams.get("treatment");

	useEffect(() => {
		setLoading(true);

		if (!id) {
			console.error("Clinic ID is undefined");
			setClinic(null);
			setLoading(false);
			return;
		}

		const clinicIdAsNumber = parseInt(id, 10);
		const treatmentIdAsNumber = treatmentId ? parseInt(treatmentId, 10) : null;

		if (isNaN(clinicIdAsNumber)) {
			console.error("Invalid clinic ID:", id);
			setClinic(null);
			setLoading(false);
			return;
		}

		getClinic(parseInt(id, 10)).then((res) => {
			setClinic(res);
			setLoading(false);

			if (treatmentIdAsNumber !== null && !isNaN(treatmentIdAsNumber)) {
				const treatment =
					treatments.find((t) => t.id === treatmentIdAsNumber) ?? null;
				setTreatment(treatment);
			}
		});
	}, [id, treatments, treatmentId]);

	return (
		<div className="clinic">
			<Navigation />

			<section className="clinic__toolbar container">
				<ClinicToolbar clinic={clinic} />
			</section>

			{loading && <ClinicDetailSkeleton />}

			{clinic ? (
				<>
					<section className="clinic__header">
						<ImageGrid
							featured_image={clinic.featured_image}
							images={clinic.images}
						/>
						<div className="clinic__title container">
							{clinic.location && (
								<p className="paragraph--md text--secondary text--medium">
									{clinic.location.city + ", " + clinic.location.country}
								</p>
							)}{" "}
							<h2>{clinic.name}</h2>
						</div>
					</section>

					<section className="clinic__content container">
						<div className="clinic__body">
							<div className="clinic__body__section margin-top--none">
								<h5 className="clinic__body__title">About this clinic</h5>
								{clinic.description && (
									<p className="clinic__about__description margin-bottom--sm">
										{clinic.description}
									</p>
								)}

								{clinic.website && (
									<div className="clinic__website">
										<Icon.Globe size={20} />
										<a href={clinic.website} className="link">
											{clinic.website}
										</a>
									</div>
								)}
								{clinic.phone_number && (
									<div className="clinic__website">
										<Icon.Phone size={20} />
										<a href={"tel:" + clinic.phone_number} className="link">
											{clinic.phone_number}
										</a>
									</div>
								)}

								{clinic.address && (
									<div className="clinic__languages">
										<Icon.MapPin size={20} />
										<p className="text--secondary">{clinic.address}</p>
									</div>
								)}

								{clinic.languages && (
									<div className="clinic__languages">
										<Icon.MessageSquare size={20} />
										<p className="text--secondary">
											Doctors speak {clinic.languages.join(", ")}
										</p>
									</div>
								)}

								{clinic.trades && (
									<div className="clinic__languages">
										<Icon.Tag size={20} />
										<p className="text--secondary">{clinic.trades}</p>
									</div>
								)}
							</div>
							{(clinic.facilities || clinic.featuredHighlights) && (
								<div className="clinic__body__section clinic__highlights">
									<h5 className="clinic__body__title">Highlights</h5>

									{clinic.featuredHighlights && (
										<div className="clinic__highlights__featured">
											{clinic.featuredHighlights.map((highlight, index) => (
												<div
													className="clinic__highlight--featured"
													key={index}
												>
													<Icon.ThumbsUp size={20} />
													<div className="clinic__highlight__text">
														<p className="text--medium margin-bottom--xxs">
															{highlight.title}
														</p>
														<p className="paragraph--sm text--secondary">
															{highlight.description}
														</p>
													</div>
												</div>
											))}
										</div>
									)}

									{clinic.facilities &&
										clinic.facilities.map((facility, index) => (
											<div className="clinic__highlight" key={index}>
												<Icon.Check size={20} />
												{facility}
											</div>
										))}
								</div>
							)}

							{clinic.accreditations && (
								<div className="clinic__body__section clinic__accreditations">
									<h5 className="clinic__body__title">Accreditations</h5>
									<div className="clinic__accreditations">
										{clinic.accreditations.map((accreditation, index) => (
											<div className="clinic__accreditation" key={index}>
												<Icon.Award size={20} />
												<div className="clinic__accreditations__text">
													<p className="text--medium">{accreditation}</p>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
							<div className="clinic__body__section clinic__ratings">
								<h5 className="clinic__body__title">Patient Rating</h5>

								<div className="clinic__ratings__summary margin-bottom--lg">
									<Rating
										rating={clinic.rating}
										ratingCount={clinic.ratingCount}
									/>
								</div>

								{clinic.reviews &&
									clinic.reviews.map((review, index) => (
										<ReviewCard review={review} key={index} />
									))}
							</div>
						</div>
						<ClinicInfoPanel clinic={clinic} treatment={treatment} />
					</section>
				</>
			) : (
				<div className="container">
					<h3 className="text--tertiary margin-top--xxxxl margin-bottom--xxxxl margin-left--auto margin-right--auto">
						No Clinic
					</h3>
				</div>
			)}
			<Footer />
		</div>
	);
};

export default ClinicDetail;
