import { City, Clinic, Country, Speciality, Treatment } from "../types";
import { PagedResponse } from "../types/models/PagedResponse";
import { apiFetch } from "./common"; // Adjust the import path as necessary

export async function getTreatments(): Promise<Treatment[]> {
	const res = await apiFetch("GET", "/api/v1/treatments/", null, false);
	return res.json();
}

export const getSpecialities = async (): Promise<Speciality[]> => {
	const res = await apiFetch("GET", "/api/v1/specialities/", null, false);
	return res.json();
};

export const getCountries = async (): Promise<Country[]> => {
	const res = await apiFetch("GET", "/api/v1/countries/", null, false);
	return res.json();
};

export const getCities = async (): Promise<City[]> => {
	const res = await apiFetch("GET", "/api/v1/cities/", null, false);
	return res.json();
};

export const getClinics = async (
	params: Record<string, string>
): Promise<PagedResponse<Clinic>> => {
	const urlParams = new URLSearchParams(params).toString();
	const res = await apiFetch(
		"GET",
		`/api/v1/clinics?${urlParams}`,
		null,
		false
	);

	return res.json();
};

export const getClinic = async (id: number): Promise<Clinic> => {
	return apiFetch("GET", "/api/v1/clinics/" + id, null, false).then((res) => {
		return res.json();
	});
};
