import React, { useState, useRef } from "react";
import * as Icon from "react-feather";

type AccordionProps = {
	title: string;
	children: React.ReactNode;
};

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const contentRef = useRef<HTMLDivElement>(null);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div
			className={`accordion card card--interactive ${
				isOpen ? "accordion--open" : ""
			}`}
		>
			<button
				className="accordion__header text--medium"
				onClick={toggleAccordion}
			>
				{title}
				<Icon.ChevronDown className="accordion__chevron" />
			</button>
			<div
				className="accordion__content"
				ref={contentRef}
				style={{
					height: isOpen ? contentRef.current?.scrollHeight : 0,
				}}
			>
				<div className="accordion__inner">{children}</div>
			</div>
		</div>
	);
};

export default Accordion;
