import { useGlobal } from "../../contexts/GlobalContext";
import DestinationCard from "../Cards/DestinationCard/DestinationCard";
import CardCarousel from "../Carousel/Carousel";

const DestinationsTeaser = (): React.ReactElement => {
	const { countries } = useGlobal();

	return (
		<section className="home__destinations">
			<div className="home__destinations__inner">
				<div className="container">
					<h2 className="home__destinations__title">
						Discover World-Renowned Healthcare Destinations
					</h2>
					<CardCarousel>
						{countries.map((country, index) => (
							<DestinationCard country={country} key={index} />
						))}
					</CardCarousel>
				</div>
			</div>
		</section>
	);
};

export default DestinationsTeaser;
