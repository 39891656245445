import React, { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";

type CarouselProps = {
	className?: string;
	children?: React.ReactNode;
};

const Carousel: React.FC<CarouselProps> = ({ className, children }) => {
	const maskRef = useRef<HTMLDivElement>(null);
	const [isScrolledToStart, setIsScrolledToStart] = useState(true);
	const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

	const handleScroll = () => {
		if (maskRef.current) {
			const { scrollLeft, scrollWidth, clientWidth } = maskRef.current;
			setIsScrolledToStart(scrollLeft <= 0);
			setIsScrolledToEnd(scrollLeft + clientWidth >= scrollWidth);
		}
	};

	const scrollByAmount = (amount: number) => {
		if (maskRef.current) {
			maskRef.current.scrollBy({
				left: amount,
				behavior: "smooth",
			});
		}
	};

	const handleNext = () => {
		if (maskRef.current) {
			const { clientWidth } = maskRef.current;
			scrollByAmount(clientWidth * 0.25);
		}
	};

	const handlePrevious = () => {
		if (maskRef.current) {
			const { clientWidth } = maskRef.current;
			scrollByAmount(-clientWidth * 0.25);
		}
	};

	const getMaskClass = () => {
		if (isScrolledToStart) return "carousel__mask--end";
		if (isScrolledToEnd) return "carousel__mask--start";
		return "carousel__mask--both";
	};

	useEffect(() => {
		const mask = maskRef.current;
		if (mask) {
			handleScroll(); // Check button visibility on mount
			mask.addEventListener("scroll", handleScroll);
			return () => {
				mask.removeEventListener("scroll", handleScroll);
			};
		}
	}, [children]);

	return (
		<div className={`carousel ${className}`}>
			<button
				className="button button--floating button--sm button--icon carousel__previous"
				onClick={handlePrevious}
				disabled={isScrolledToStart}
			>
				<Icon.ChevronLeft size={20} />
			</button>
			<button
				className="button button--floating button--sm button--icon carousel__next"
				onClick={handleNext}
				disabled={isScrolledToEnd}
			>
				<Icon.ChevronRight size={20} />
			</button>
			<div className={`carousel__mask ${getMaskClass()}`} ref={maskRef}>
				<div className="carousel__items">{children}</div>
			</div>
		</div>
	);
};

export default Carousel;
