import {
	BrowserRouter,
	Route,
	Routes,
	createBrowserRouter,
} from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { ComparisonProvider } from "./contexts/ComparisonContext";
import { GlobalProvider } from "./contexts/GlobalContext";
import BrowseClinics from "./screens/BrowseClinics/BrowseClinics";
import ClinicDetail from "./screens/ClinicDetail/ClinicDetail";
import Comparison from "./screens/Comparison/Comparison";
import DestinationDetail from "./screens/DestinationDetail/DestinationDetail";
import { Home } from "./screens/Home";
import TreatmentDetail from "./screens/TreatmentDetail/TreatmentDetail";

const router = createBrowserRouter([
	{
		element: <Navigation />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/clinics",
				element: <BrowseClinics />,
			},
			{
				path: "/clinic",
				element: <ClinicDetail />,
			},
		],
	},
]);

const App = () => {
	return (
		<GlobalProvider>
			<ComparisonProvider>
				<BrowserRouter>
					<ScrollToTop />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/treatments/:id" element={<TreatmentDetail />} />
						<Route path="/destinations/:id" element={<DestinationDetail />} />
						<Route path="/clinics" element={<BrowseClinics />} />
						<Route path="/clinics/:id" element={<ClinicDetail />} />
						<Route path="/comparison" element={<Comparison />} />
					</Routes>
				</BrowserRouter>
			</ComparisonProvider>
		</GlobalProvider>
	);
};

export default App;
