import * as Icon from "react-feather";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navigation/Navigation";
import { useComparison } from "../../contexts/ComparisonContext";
import { useGlobal } from "../../contexts/GlobalContext";
import { getTreatmentById } from "../../util/clinicUtil";

const Comparison = (): React.ReactElement => {
	const { comparisonCart } = useComparison();
	const { treatments } = useGlobal();
	const navigate = useNavigate();

	const uniqueLanguages = Array.from(
		new Set(comparisonCart.flatMap((clinic) => clinic.languages || []))
	);
	const uniqueAccreditations = Array.from(
		new Set(comparisonCart.flatMap((clinic) => clinic.accreditations || []))
	);
	const uniqueTreatments = Array.from(
		new Set(
			comparisonCart.flatMap(
				(clinic) =>
					clinic.treatments?.map((t) =>
						getTreatmentById(t.treatment, treatments)
					) || []
			)
		)
	);

	return (
		<>
			<Navigation />
			<div className="comparison">
				<div className="container container--fluid">
					<div className="comparison__container">
						<div className="comparison__toolbar">
							<button
								className="button button--sm button--floating"
								onClick={() => navigate(-1)}
							>
								<Icon.ChevronLeft size={20} />
								Back
							</button>
						</div>

						<div className="comparison-table">
							{comparisonCart.length > 0 ? (
								<table>
									<tr>
										<td className="border-bottom--none"></td>
										{comparisonCart.map((clinic) => (
											<td key={clinic.id} className="border-bottom--none">
												<div className="comparison-table__clinic__image">
													<img src={clinic.featured_image} alt="" />
												</div>
											</td>
										))}
									</tr>

									<tr>
										<td className="border-bottom--none"></td>
										{comparisonCart.map((clinic) => (
											<th
												key={clinic.id}
												className="comparison-table__clinic__header text--centered border-bottom--none"
											>
												{clinic.location && (
													<p className="paragraph--sm text--secondary text--medium margin-bottom--xs">
														{clinic.location.city +
															", " +
															clinic.location.country}
													</p>
												)}

												<h5>{clinic.name}</h5>
											</th>
										))}
									</tr>

									{/* Languages Section */}
									<tr>
										<th>
											<h5>Spoken Languages</h5>
										</th>
									</tr>
									{uniqueLanguages.map((language) => (
										<tr key={language}>
											<td>{language}</td>
											{comparisonCart.map((clinic) => (
												<td key={clinic.id}>
													{clinic.languages?.includes(language) ? (
														<Icon.Check />
													) : (
														<Icon.Minus className="text--faded" />
													)}
												</td>
											))}
										</tr>
									))}

									{/* Accreditations Section */}
									<tr>
										<th>
											<h5>Accreditations</h5>
										</th>
									</tr>
									{uniqueAccreditations.map((accreditation) => (
										<tr key={accreditation}>
											<td>{accreditation}</td>
											{comparisonCart.map((clinic) => (
												<td key={clinic.id}>
													{clinic.accreditations?.includes(accreditation) ? (
														<Icon.Check />
													) : (
														<Icon.Minus className="text--faded" />
													)}
												</td>
											))}
										</tr>
									))}

									{/* Treatments Section */}
									<tr>
										<th>
											<h5>Available Treatments</h5>
										</th>
									</tr>
									{uniqueTreatments.map((treatment) => (
										<tr key={treatment?.id}>
											<td>{treatment?.name}</td>
											{comparisonCart.map((clinic) => (
												<td key={clinic.id}>
													{clinic.treatments?.some(
														(t) => t.treatment === treatment?.id
													) ? (
														<Icon.Check />
													) : (
														<Icon.Minus className="text--faded" />
													)}
												</td>
											))}
										</tr>
									))}
								</table>
							) : (
								<p className="text--secondary comparison__empty">
									No clinics selected for comparison.
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Comparison;
