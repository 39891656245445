import React from "react";
import * as Icon from "react-feather";

export enum SortMode {
	Rating = "Recommended",
	Price = "Best Price",
}

type ClinicsListToolbarProps = {
	isGridView: boolean;
	sortMode: SortMode;
	showFilterPanel?: boolean;
	onSortChange: (sortMode: SortMode) => void;
	onGridViewChange: (isGridView: boolean) => void;
	onFiltersOpen: (isFiltersOpen: boolean) => void;
};

const ClinicsListToolbar: React.FC<ClinicsListToolbarProps> = ({
	isGridView,
	sortMode,
	showFilterPanel = false,
	onSortChange,
	onGridViewChange,
	onFiltersOpen,
}) => {
	return (
		<div className="list__toolbar">
			<select
				className="button button--sm button--dropdown"
				value={sortMode}
				onChange={(e) => {
					const value = e.target.value as keyof typeof SortMode;
					onSortChange(SortMode[value]);
				}}
			>
				<option value={SortMode.Rating}>{SortMode.Rating}</option>
				<option value={SortMode.Price}>{SortMode.Price}</option>
			</select>

			{showFilterPanel && (
				<button
					className="button button--sm list__toolbar__filterbutton"
					onClick={() => onFiltersOpen(true)}
				>
					<Icon.Sliders size={16} />
					Filters
				</button>
			)}
			<div className="list__toolbar__switch">
				<button
					className={`button button--sm button--icon ${
						!isGridView ? "button--active" : ""
					}`}
					onClick={() => onGridViewChange(false)}
				>
					<Icon.List size={20} />
				</button>
				<button
					className={`button button--sm button--icon ${
						isGridView ? "button--active" : ""
					}`}
					onClick={() => onGridViewChange(true)}
				>
					<Icon.Grid size={20} />
				</button>
			</div>
		</div>
	);
};

export default ClinicsListToolbar;
