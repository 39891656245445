const ClinicDetailSkeleton = (): React.ReactElement => {
	return (
		<div className="clinic__skeleton">
			<section className="clinic__header">
				<div className="image-grid">
					<div className="image-grid__inner container">
						<div className="image-grid__featured">
							<div className="image-grid__image skeleton-box" />
						</div>
						<div className="image-grid__images">
							<div className="image-grid__image skeleton-box" />
							<div className="image-grid__image skeleton-box" />
							<div className="image-grid__image skeleton-box" />
							<div className="image-grid__image skeleton-box" />
						</div>
					</div>
				</div>
				<div className="clinic__title container">
					<div className="paragraph--md skeleton-box" />
					<div className="h2 skeleton-box" />
				</div>
			</section>

			<section className="clinic__content container">
				<div className="clinic__body skeleton-box" />
				<div className="clinic__panel skeleton-box" />
			</section>
		</div>
	);
};

export default ClinicDetailSkeleton;
