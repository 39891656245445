import React from "react";
import { Clinic } from "../../types";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import "leaflet/dist/leaflet.css";

type ClinicMapProps = {
	clinic: Clinic;
	className?: string;
};

const ClinicMap: React.FC<ClinicMapProps> = ({ clinic, className }) => {
	const { featured_image, location } = clinic;

	const iconMarkup: React.ReactNode = (
		<div className="clinic__map__pin">
			<img className="clinic__map__pin__box" src={featured_image} alt="" />
			<div className="clinic__map__pin__line" />
			<div className="clinic__map__pin__dot" />
		</div>
	);

	const zoom = 13;
	const customMarkerIcon = divIcon({
		html: renderToStaticMarkup(iconMarkup),
	});

	return (
		<div className={`clinic__map ${className || ""}`}>
			{location?.coordinates ? (
				<MapContainer
					center={location?.coordinates}
					zoom={zoom}
					scrollWheelZoom={false}
					className="clinic__map__image"
					zoomAnimation={true}
					doubleClickZoom={true}
					zoomControl={false}
				>
					<TileLayer
						url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
					/>
					<Marker position={location?.coordinates} icon={customMarkerIcon} />
				</MapContainer>
			) : (
				<div className="clinic__map__image clinic__map__placeholder">
					<p className="text--secondary">No location data available yet.</p>
				</div>
			)}
		</div>
	);
};

export default ClinicMap;
