import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { useGlobal } from "../../contexts/GlobalContext";
import { Treatment } from "../../types";
import Badge from "../Badge/Badge";
import ComparisonCart from "../ComparisonCart/ComparisonCart";
import Logo from "../Logo/Logo";

type NavigationProps = {
	isDarkBackground?: boolean; // Indicates if the navbar starts on a dark background
};

const Navigation: React.FC<NavigationProps> = ({
	isDarkBackground = false,
}) => {
	const [scrolled, setScrolled] = useState(false);
	const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
	const { treatments, countries } = useGlobal();

	const groupedTreatments = treatments.reduce((groups, treatment) => {
		const specialityName = treatment.speciality?.name ?? "Other";
		if (!groups[specialityName]) {
			groups[specialityName] = [];
		}
		groups[specialityName].push(treatment);
		return groups;
	}, {} as Record<string, Treatment[]>);

	useEffect(() => {
		const handleScroll = () => {
			setScrolled(window.scrollY > 80);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<nav
			className={`navigation 
    ${scrolled ? "navigation--scrolled" : ""} 
    ${isDarkBackground ? "navigation--dark" : ""}`.trim()}
		>
			<div className="navigation__inner">
				<div className="navigation__leading">
					<Link to="/" className="navigation__logo navigation__item__link">
						<Logo />
					</Link>
				</div>
				<div className="navigation__menu">
					<div className="navigation__action navigation__hamburger">
						<button
							onClick={() => setIsMobileMenuVisible(true)}
							className="button button--sm button--icon "
						>
							<Icon.Menu size={20} />
						</button>
					</div>
					<ul
						className={`navigation__menu__list ${
							isMobileMenuVisible ? "menu-mobile--visible" : ""
						}`}
					>
						<li className="navigation__mobile__close">
							<button
								className="button button--sm button--icon button--floating"
								onClick={() => setIsMobileMenuVisible(false)}
							>
								<Icon.X size={20} />
							</button>
						</li>
						<li className="navigation__item">
							<div className="navigation__item__link">Treatments</div>
							<div className="navigation__submenu">
								{Object.entries(groupedTreatments).map(
									([specialityName, treatments]) => (
										<ul
											className="navigation__submenu__list list"
											key={specialityName}
										>
											<li className="paragraph--sm text--secondary text--medium list__divider">
												{specialityName}
											</li>
											{treatments.map((treatment) => (
												<li key={treatment.id}>
													<Link
														to={"/treatments/" + treatment.id}
														className="list__item__button"
													>
														<Badge color={treatment.speciality?.color}>
															{treatment.name}
														</Badge>
													</Link>
												</li>
											))}
										</ul>
									)
								)}
							</div>
						</li>
						<li className="navigation__item">
							<div className="navigation__item__link">Destinations</div>
							<ul className="navigation__submenu list">
								<li className="paragraph--sm text--secondary text--medium list__divider">
									Popular destinations
								</li>
								{countries.map((country) => (
									<li key={country.id}>
										<Link
											to={"/destinations/" + country.id}
											className="list__item__button"
										>
											{country.name}
										</Link>
									</li>
								))}
							</ul>
						</li>
					</ul>
				</div>
				<ul className="navigation__actions">
					<li className="navigation__action">
						{/* <button
							onClick={() => {}}
							className="button button--sm button--icon"
						>
							<Icon.Sun size={20} />
						</button> */}
					</li>
					<li className="navigation__action comparison-cart">
						<ComparisonCart />
					</li>
					{/* <li className="navigation__action">
						<a href="" className="button button--sm button--icon">
							<Icon.Heart size={20} />
						</a>
					</li> */}
				</ul>
			</div>
		</nav>
	);
};

export default Navigation;
