import React from "react";

type HeroProps = {
	className?: string;
	isFullHeight?: boolean;
	image?: string;
	tint?: string;
	children?: React.ReactNode; // Allows passing JSX as children
};

const Hero: React.FC<HeroProps> = ({
	children,
	className = "",
	isFullHeight = false,
	tint,
	image,
}) => {
	return (
		<section
			className={`hero ${isFullHeight ? "hero--full" : ""} ${
				image ? "hero--image" : ""
			} ${className}`}
		>
			<div
				className="hero__inner"
				style={{
					backgroundImage: image ? `url(${image})` : undefined,
				}}
			>
				<div
					className="hero__tint"
					style={tint ? { backgroundColor: tint } : undefined}
				/>
				<div className="container">
					<div className="hero__container">{children}</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
