import Footer from "../../components/Footer/Footer";
import ClinicsList from "../../components/Grids/ClinicsList/ClinicsList";
import Navigation from "../../components/Navigation/Navigation";

const BrowseClinics = (): React.ReactElement => {
	return (
		<>
			<Navigation />
			<div className="results">
				<div className="container">
					{/* Disable the search field until backend filters are available */}
					{/* <Search /> */}
					{/* Set the showFilterPanel prop to false while there are no backend filters available */}
					<ClinicsList showFilterPanel={false} />
				</div>
			</div>
			<Footer />
		</>
	);
};

export default BrowseClinics;
