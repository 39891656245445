import React, { useState } from "react";
import { Clinic } from "../types";

const ComparisonContext = React.createContext<{
	comparisonCart: Clinic[];
	notification: Clinic | null;
	addToCart: (clinic: Clinic) => void;
	removeFromCart: (clinicId: number) => void;
}>({
	comparisonCart: [],
	notification: null,
	addToCart: () => {},
	removeFromCart: () => {},
});

export const ComparisonProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [comparisonCart, setComparisonCart] = useState<Clinic[]>([]);
	const [notification, setNotification] = useState<Clinic | null>(null);

	const addToCart = (clinic: Clinic) => {
		setComparisonCart((prev) => {
			if (prev.some((c) => c.id === clinic.id)) {
				return prev.filter((c) => c.id !== clinic.id);
			} else {
				setNotification(clinic);
				setTimeout(() => setNotification(null), 2000);
				return [...prev, clinic];
			}
		});
	};

	const removeFromCart = (clinicId: number) => {
		setComparisonCart((prev) =>
			prev.filter((clinic) => clinic.id !== clinicId)
		);
	};

	return (
		<ComparisonContext.Provider
			value={{ comparisonCart, addToCart, removeFromCart, notification }}
		>
			{children}
		</ComparisonContext.Provider>
	);
};

export const useComparison = () => React.useContext(ComparisonContext);
