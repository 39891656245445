import React from "react";
import * as Icon from "react-feather";
import { useNavigate } from "react-router-dom";
import { Clinic } from "../../types";
import CompareButton from "../CompareButton/CompareButton";

type ClinicToolbarProps = {
	clinic?: Clinic | null;
};

const ClinicToolbar: React.FC<ClinicToolbarProps> = ({ clinic }) => {
	const navigate = useNavigate();

	return (
		<div className="clinic-toolbar">
			<button className="button button--sm" onClick={() => navigate(-1)}>
				<Icon.ChevronLeft size={20} />
				Back
			</button>

			{clinic && (
				<div className="clinic-toolbar__actions">
					<CompareButton clinic={clinic} />

					{/* <button className="button button--sm button--icon">
					<Icon.Heart size={20} />
				</button> */}
				</div>
			)}
		</div>
	);
};

export default ClinicToolbar;
