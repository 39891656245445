import { useState } from "react";
import { useGlobal } from "../../contexts/GlobalContext";
import Badge from "../Badge/Badge";
import TreatmentsGrid from "../Grids/TreatmentsList/TreatmentsList";
import TabItem from "../TabItem/TabItem";
import Tabs from "../Tabs/Tabs";

const TreatmentsTeaser = (): React.ReactElement => {
	const [selectedSpeciality, setSelectedSpeciality] = useState<string | null>(
		null
	);
	const { treatments, specialities, isLoading } = useGlobal();

	const filteredTreatments = selectedSpeciality
		? treatments.filter(
				(treatment) => treatment.speciality?.name === selectedSpeciality
		  )
		: treatments;

	return (
		<section className="home__treatments">
			<div className="home__treatments__container container">
				<h2 className="home__treatments__title">
					Discover world-class clinics and healthcare providers in top
					destinations. Find the right treatment at the right price—
					<span className="text--accent">wherever you want to go.</span>
				</h2>

				{isLoading && (
					<div className="home__treatments__grid treatments-list__skeleton skeleton">
						<div className="skeleton-box" />
						<div className="skeleton-box" />
						<div className="skeleton-box" />
						<div className="skeleton-box" />
						<div className="skeleton-box" />
						<div className="skeleton-box" />
						<div className="skeleton-box" />
						<div className="skeleton-box" />
					</div>
				)}

				{!isLoading && (
					<Tabs>
						<TabItem
							tabStyle="tag"
							onClick={() => setSelectedSpeciality(null)}
							selected={selectedSpeciality === null}
						>
							<Badge>All</Badge>
						</TabItem>

						{specialities.map((speciality, index) => (
							<TabItem
								key={index}
								tabStyle="tag"
								selected={selectedSpeciality === speciality.name}
								onClick={() => setSelectedSpeciality(speciality.name)}
							>
								<Badge color={speciality.color}>{speciality.name}</Badge>
							</TabItem>
						))}
					</Tabs>
				)}

				{filteredTreatments.length > 0 && (
					<TreatmentsGrid
						treatments={filteredTreatments}
						className="home__treatments__grid"
					/>
				)}

				{filteredTreatments.length === 0 && !isLoading && (
					<p className="text--secondary text--centered margin-top--xxl margin-bottom--xxl">
						No treatments for this speciality available yet.
					</p>
				)}
			</div>
		</section>
	);
};
export default TreatmentsTeaser;
