import Accordion from "../../components/Accordion/Accordion";

const Faq = (): React.ReactElement => {
	return (
		<section className="faq">
			<div className="container faq__container">
				<h2 className="faq__title margin-bottom--xxl">FAQ</h2>

				<Accordion title="What is medical tourism, and how does it work?">
					<p>
						Lorem ipsum dolor sit amet consectetur adipsicing enim ad lorem
						ipsum dolor sit amet consectetur adipsicing enum ad lorem ipsum
						dolor sit amet.
					</p>
				</Accordion>

				<Accordion title="How do I choose the right clinic for my treatment abroad?">
					<p>
						Lorem ipsum dolor sit amet consectetur adipsicing enim ad lorem
						ipsum dolor sit amet consectetur adipsicing enum ad lorem ipsum
						dolor sit amet.
					</p>
				</Accordion>

				<Accordion title="Are the healthcare providers internationally accredited?">
					<p>
						Lorem ipsum dolor sit amet consectetur adipsicing enim ad lorem
						ipsum dolor sit amet consectetur adipsicing enum ad lorem ipsum
						dolor sit amet.
					</p>
				</Accordion>

				<Accordion title="How can I compare the cost of treatments across different countries?">
					<p>
						Lorem ipsum dolor sit amet consectetur adipsicing enim ad lorem
						ipsum dolor sit amet consectetur adipsicing enum ad lorem ipsum
						dolor sit amet.
					</p>
				</Accordion>

				<Accordion title="Is it safe to travel for medical procedures during recovery?">
					<p>
						Lorem ipsum dolor sit amet consectetur adipsicing enim ad lorem
						ipsum dolor sit amet consectetur adipsicing enum ad lorem ipsum
						dolor sit amet.
					</p>
				</Accordion>

				<Accordion title="What’s included in the treatment packages or booking process?">
					<p>
						Lorem ipsum dolor sit amet consectetur adipsicing enim ad lorem
						ipsum dolor sit amet consectetur adipsicing enum ad lorem ipsum
						dolor sit amet.
					</p>
				</Accordion>
			</div>
		</section>
	);
};

export default Faq;
