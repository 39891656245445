import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { getClinics } from "../../../api/api";
import { Clinic, Filters } from "../../../types";
import { PagedResponse } from "../../../types/models/PagedResponse";
import { filterClinics, sortClinics } from "../../../util/clinicUtil";
import ClinicCard from "../../Cards/ClinicCard/ClinicCard";
import ClinicsListToolbar, {
	SortMode,
} from "../../ClinicsListToolbar/ClinicsListToolbar";
import FilterPanel from "../../FilterPanel/FilterPanel";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useGlobal } from "../../../contexts/GlobalContext";

type ClinicsListProps = {
	parentFilters?: Filters;
	showFilterPanel?: boolean;
	className?: string;
};

const ClinicsList: React.FC<ClinicsListProps> = ({
	parentFilters = null,
	showFilterPanel = false,
	className,
}) => {
	const [clinics, setClinics] = useState<Clinic[]>([]);
	const [sortedFilteredClinics, setSortedFilteredClinics] = useState<Clinic[]>(
		[]
	);

	// View and filter options
	const [filters, setFilters] = useState<Filters | null>(null);
	const [isGridView, setIsGridView] = useState(false);
	const [sortOption, setSortOption] = useState<SortMode>(SortMode.Rating);
	const [isFiltersOpen, setIsFiltersOpen] = useState(false); // Mobile only

	// Search Config
	const perPageContent = 24;
	const [clinicsLoading, setClinicsLoading] = useState<boolean>(false);
	const [limit] = useState(perPageContent);
	const [offset, setOffset] = useState(0);
	const [showMore, setShouldShowMore] = useState(true);
	const [totalCount, setTotalCount] = useState(0);

	// Globals
	const { isLoading } = useGlobal();

	const loadClinics = async () => {
		let params = {
			limit: limit.toString(),
			offset: offset.toString(),
		};

		setClinicsLoading(true);
		try {
			const response: PagedResponse<Clinic> = await getClinics(params);
			setClinics((prev) => [...prev, ...response.results]);
			setOffset((prev) => prev + response.results.length);
			setTotalCount(response.count);
			setShouldShowMore(!!response.next);
		} catch (err: any) {
			console.log(err.message);
		} finally {
			setClinicsLoading(false);
		}
	};

	useEffect(() => {
		loadClinics();
	}, []);

	useEffect(() => {
		// Refresh results once filters or sort change
		const filtered = filterClinics(clinics, filters);
		const sorted = sortClinics(filtered, sortOption, filters?.treatment);
		setSortedFilteredClinics(sorted);
	}, [clinics, filters, sortOption]);

	return (
		<div className={`clinics-list ${className || ""}`}>
			<FilterPanel
				clinics={clinics}
				parentFilters={parentFilters}
				onFiltersChange={setFilters}
				onFiltersOpen={setIsFiltersOpen}
				resultsCount={totalCount}
				className={`${isFiltersOpen ? "filters--open" : ""} ${
					!showFilterPanel ? "filters--hidden" : ""
				}`}
			/>

			<div className="clinics-list__main">
				<ClinicsListToolbar
					isGridView={isGridView}
					sortMode={sortOption}
					showFilterPanel={showFilterPanel}
					onGridViewChange={setIsGridView}
					onSortChange={setSortOption}
					onFiltersOpen={setIsFiltersOpen}
				/>

				{(clinicsLoading || isLoading) && (
					<div className="clinics-list__skeleton skeleton">
						<div className="skeleton-box" />
						<div className="skeleton-box" />
						<div className="skeleton-box" />
						<div className="skeleton-box" />
					</div>
				)}

				{!clinicsLoading &&
					!isLoading &&
					sortedFilteredClinics.length === 0 && (
						<div className="card clinics-list__empty">
							<p className="text--secondary padding-top--xxxxl padding-bottom--xxxxl text--centered">
								No clinics for your search available.
							</p>
						</div>
					)}

				<TransitionGroup className="clinics-list__results">
					{sortedFilteredClinics.map((clinic) => {
						let nodeRef = React.createRef<HTMLDivElement | null>();
						return (
							<CSSTransition
								classNames="card-transition"
								timeout={400}
								key={clinic.id}
								nodeRef={nodeRef}
							>
								<ClinicCard
									ref={nodeRef}
									clinic={clinic}
									treatment={filters?.treatment ?? parentFilters?.treatment}
									compact={isGridView}
								/>
							</CSSTransition>
						);
					})}
				</TransitionGroup>

				{showMore &&
					!clinicsLoading &&
					!isLoading &&
					sortedFilteredClinics.length > 0 && (
						<button
							className="button margin-horizontal--auto margin-top--lg"
							onClick={loadClinics}
							disabled={clinicsLoading}
						>
							<Icon.MoreHorizontal size={20} />
							Show more
						</button>
					)}
			</div>
		</div>
	);
};

export default ClinicsList;
