import React from "react";

type LogoProps = {
	vertical?: boolean;
};

const Logo: React.FC<LogoProps> = ({ vertical = false }) => {
	return vertical ? (
		<svg
			className="logo"
			viewBox="0 0 297 238"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g fill="currentColor">
				<path d="M245.656 237.168C268.6 195.888 268.6 154.608 245.656 113.328H254.008C266.584 133.488 272.872 154.128 272.872 175.248C272.872 196.368 266.584 217.008 254.008 237.168H245.656Z" />
				<path d="M156.109 119.094H191.389C207.901 119.094 220.621 123.558 229.549 132.486C238.573 141.318 243.085 153.942 243.085 170.358C243.085 186.678 238.669 199.254 229.837 208.086C221.101 216.918 208.573 221.334 192.253 221.334H156.109V119.094ZM191.389 202.902C201.181 202.902 208.477 200.262 213.277 194.982C218.077 189.606 220.477 181.35 220.477 170.214C220.477 159.174 218.077 150.966 213.277 145.59C208.477 140.214 201.181 137.526 191.389 137.526H177.997V202.902H191.389Z" />
				<path d="M34.7812 119.094H64.0132L90.0772 193.542L115.997 119.094H145.229V221.334H123.341V155.094L99.2932 221.046H80.5732L56.6693 155.094V221.334H34.7812V119.094Z" />
				<path d="M18.864 237.168C6.28801 217.008 0 196.368 0 175.248C0 154.128 6.28801 133.488 18.864 113.328H27.216C4.368 154.608 4.368 195.888 27.216 237.168H18.864Z" />
				<path d="M216.641 0.84375H257.537C265.601 0.84375 272.609 2.18775 278.561 4.87575C284.513 7.56375 289.025 11.4517 292.097 16.5398C295.265 21.5318 296.849 27.4358 296.849 34.2518C296.849 41.0678 295.265 47.0198 292.097 52.1078C289.025 57.0998 284.513 60.9878 278.561 63.7718C272.609 66.4598 265.601 67.8038 257.537 67.8038H238.529V103.084H216.641V0.84375ZM256.241 49.3717C262.097 49.3717 266.561 48.0758 269.633 45.4838C272.801 42.8918 274.385 39.1478 274.385 34.2518C274.385 29.3558 272.801 25.6597 269.633 23.1638C266.561 20.5718 262.097 19.2758 256.241 19.2758H238.529V49.3717H256.241Z" />
				<path d="M183.906 0.84375H205.794V103.084H183.906V0.84375Z" />
				<path d="M90.6875 0.84375H135.471C142.863 0.84375 149.343 2.09175 154.911 4.58775C160.479 7.08375 164.752 10.6357 167.728 15.2438C170.704 19.8518 172.191 25.2277 172.191 31.3717C172.191 35.9798 171.231 40.1558 169.311 43.8998C167.391 47.5478 164.703 50.5238 161.247 52.8278C157.791 55.1318 153.856 56.5718 149.44 57.1478L149.008 55.8518C155.824 55.8518 161.008 57.3398 164.56 60.3158C168.112 63.1958 170.128 67.6598 170.608 73.7078L173.199 103.084H150.88L149.008 77.1638C148.72 73.1318 147.471 70.2038 145.263 68.3798C143.055 66.5558 139.407 65.6438 134.319 65.6438H112.576V103.084H90.6875V0.84375ZM133.023 47.3558C138.303 47.3558 142.383 46.1558 145.263 43.7558C148.239 41.2598 149.728 37.7558 149.728 33.2438C149.728 28.6357 148.239 25.1797 145.263 22.8757C142.287 20.4758 137.967 19.2758 132.303 19.2758H112.576V47.3558H133.023Z" />
				<path d="M30.672 19.2758H0V0.84375H83.232V19.2758H52.704V103.084H30.672V19.2758Z" />
			</g>
		</svg>
	) : (
		<svg
			className="logo"
			viewBox="0 0 576 128"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g fill="currentColor">
				<path d="M548.25 125.918C571.194 84.6381 571.194 43.3581 548.25 2.07812H556.602C569.178 22.2381 575.466 42.8781 575.466 63.9981C575.466 85.1181 569.178 105.758 556.602 125.918H548.25Z" />
				<path d="M458.703 7.84375H493.983C510.495 7.84375 523.215 12.3077 532.143 21.2357C541.167 30.0677 545.679 42.6917 545.679 59.1078C545.679 75.4278 541.263 88.0038 532.431 96.8358C523.695 105.668 511.167 110.084 494.847 110.084H458.703V7.84375ZM493.983 91.6518C503.775 91.6518 511.071 89.0118 515.871 83.7318C520.671 78.3558 523.071 70.0998 523.071 58.9638C523.071 47.9238 520.671 39.7158 515.871 34.3398C511.071 28.9638 503.775 26.2758 493.983 26.2758H480.591V91.6518H493.983Z" />
				<path d="M337.375 7.84375H366.607L392.671 82.2918L418.591 7.84375H447.823V110.084H425.935V43.8438L401.887 109.796H383.167L359.263 43.8438V110.084H337.375V7.84375Z" />
				<path d="M321.458 125.918C308.882 105.758 302.594 85.1181 302.594 63.9981C302.594 42.8781 308.882 22.2381 321.458 2.07812H329.81C306.962 43.3581 306.962 84.6381 329.81 125.918H321.458Z" />
				<path d="M216.641 7.84375H257.537C265.601 7.84375 272.609 9.18775 278.561 11.8757C284.513 14.5637 289.025 18.4517 292.097 23.5398C295.265 28.5318 296.849 34.4358 296.849 41.2518C296.849 48.0678 295.265 54.0198 292.097 59.1078C289.025 64.0998 284.513 67.9878 278.561 70.7718C272.609 73.4598 265.601 74.8038 257.537 74.8038H238.529V110.084H216.641V7.84375ZM256.241 56.3717C262.097 56.3717 266.561 55.0758 269.633 52.4838C272.801 49.8918 274.385 46.1478 274.385 41.2518C274.385 36.3558 272.801 32.6597 269.633 30.1638C266.561 27.5718 262.097 26.2758 256.241 26.2758H238.529V56.3717H256.241Z" />
				<path d="M183.906 7.84375H205.794V110.084H183.906V7.84375Z" />
				<path d="M90.6875 7.84375H135.472C142.864 7.84375 149.344 9.09175 154.912 11.5878C160.48 14.0837 164.751 17.6357 167.728 22.2438C170.704 26.8518 172.192 32.2277 172.192 38.3717C172.192 42.9798 171.232 47.1558 169.312 50.8998C167.392 54.5478 164.704 57.5238 161.248 59.8278C157.792 62.1318 153.856 63.5718 149.44 64.1478L149.007 62.8518C155.823 62.8518 161.008 64.3398 164.56 67.3158C168.112 70.1958 170.128 74.6598 170.608 80.7078L173.2 110.084H150.88L149.007 84.1638C148.719 80.1318 147.471 77.2038 145.263 75.3798C143.055 73.5558 139.407 72.6438 134.319 72.6438H112.576V110.084H90.6875V7.84375ZM133.024 54.3558C138.304 54.3558 142.383 53.1558 145.263 50.7558C148.239 48.2598 149.728 44.7558 149.728 40.2438C149.728 35.6357 148.239 32.1797 145.263 29.8757C142.287 27.4758 137.968 26.2758 132.304 26.2758H112.576V54.3558H133.024Z" />
				<path d="M30.672 26.2758H0V7.84375H83.232V26.2758H52.704V110.084H30.672V26.2758Z" />
			</g>
		</svg>
	);
};

export default Logo;
