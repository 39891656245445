import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Badge from "../../components/Badge/Badge";
import Carousel from "../../components/Carousel/Carousel";
import Footer from "../../components/Footer/Footer";
import ClinicsList from "../../components/Grids/ClinicsList/ClinicsList";
import Hero from "../../components/Hero/Hero";
import Navigation from "../../components/Navigation/Navigation";
import TabItem from "../../components/TabItem/TabItem";
import { useGlobal } from "../../contexts/GlobalContext";
import { Treatment } from "../../types";
import { getTreatmentById } from "../../util/clinicUtil";

const TreatmentDetail = (): React.ReactElement => {
	const { id } = useParams();
	const [treatment, setTreatment] = useState<Treatment | null>(null);
	const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
	const { treatments, countries } = useGlobal();

	useEffect(() => {
		if (!id) {
			console.error("Treatment ID is undefined");
			setTreatment(null);
			return;
		}

		const treatmentIdAsNumber = parseInt(id, 10);

		if (isNaN(treatmentIdAsNumber)) {
			console.error("Invalid treatment ID:", id);
			setTreatment(null);
			return;
		}

		const matchingTreatment = getTreatmentById(treatmentIdAsNumber, treatments);
		setTreatment(matchingTreatment);
	}, [id, treatments]);

	return (
		<div className="treatment">
			<Navigation />
			<Hero className="treatment__hero" tint={treatment?.speciality?.color}>
				<Badge color={treatment?.speciality?.color}>
					{treatment?.speciality?.name}
				</Badge>
				<h1 className="margin-top--md">{treatment?.name}</h1>
				<Carousel>
					<TabItem
						tabStyle="transparent"
						selected={selectedCountry === null}
						onClick={() => setSelectedCountry(null)}
					>
						Worldwide
					</TabItem>
					{countries.map((country, index) => (
						<TabItem
							tabStyle="transparent"
							key={index}
							selected={selectedCountry === country.name}
							onClick={() => setSelectedCountry(country.name)}
						>
							{country.name}
						</TabItem>
					))}
				</Carousel>
			</Hero>

			<div className="container">
				<ClinicsList
					parentFilters={{ treatment: treatment, destination: selectedCountry }}
				/>
			</div>

			<Footer />
		</div>
	);
};

export default TreatmentDetail;
