import { useEffect, useState } from "react";
import React from "react";
import { City, Country, Speciality, Treatment } from "../types";
import {
	getCities,
	getCountries,
	getSpecialities,
	getTreatments,
} from "../api/api";

const GlobalContext = React.createContext<{
	treatments: Treatment[];
	specialities: Speciality[];
	countries: Country[];
	cities: City[];
	isLoading: boolean;
}>({
	treatments: [],
	specialities: [],
	countries: [],
	cities: [],
	isLoading: true,
});

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [treatments, setTreatments] = useState<Treatment[]>([]);
	const [specialities, setSpecialities] = useState<Speciality[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [cities, setCities] = useState<City[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	async function loadData() {
		try {
			const [
				fetchedTreatments,
				fetchedSpecialities,
				fetchedCountries,
				fetchedCities,
			] = await Promise.all([
				getTreatments(),
				getSpecialities(),
				getCountries(),
				getCities(),
			]);
			setTreatments(fetchedTreatments);
			setSpecialities(fetchedSpecialities);
			setCountries(fetchedCountries);
			setCities(fetchedCities);
			setIsLoading(false);
		} catch (err) {
			console.log((err as Error).message);
		}
	}

	useEffect(() => {
		loadData();
	}, []);

	return (
		<GlobalContext.Provider
			value={{ treatments, specialities, countries, cities, isLoading }}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export const useGlobal = () => React.useContext(GlobalContext);
