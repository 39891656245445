import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Carousel from "../../components/Carousel/Carousel";
import Footer from "../../components/Footer/Footer";
import ClinicsList from "../../components/Grids/ClinicsList/ClinicsList";
import Hero from "../../components/Hero/Hero";
import Navigation from "../../components/Navigation/Navigation";
import TabItem from "../../components/TabItem/TabItem";
import { useGlobal } from "../../contexts/GlobalContext";
import { Country, Treatment } from "../../types";

const DestinationDetail = (): React.ReactElement => {
	const { id } = useParams();
	const [destination, setDestination] = useState<Country | null>(null);
	const [selectedTreatment, setSelectedTreatment] = useState<Treatment | null>(
		null
	);
	const { treatments, countries } = useGlobal();

	useEffect(() => {
		if (!id) {
			console.error("Destination ID is undefined");
			setDestination(null);
			return;
		}

		const destinationIdAsNumber = parseInt(id, 10);

		if (isNaN(destinationIdAsNumber)) {
			console.error("Invalid destination ID:", id);
			setDestination(null);
			return;
		}

		const matchingDestination =
			countries.find((country) => country.id === destinationIdAsNumber) ?? null;
		setDestination(matchingDestination);
	}, [id, countries]);

	return (
		<div className="treatment">
			<Navigation isDarkBackground={destination?.image !== null} />
			<Hero className="treatment__hero" image={destination?.image}>
				<p className="text--medium">{destination?.region}</p>
				<h1 className="margin-top--md">{destination?.name}</h1>
				<Carousel>
					<TabItem
						tabStyle="transparent"
						selected={selectedTreatment === null}
						onClick={() => setSelectedTreatment(null)}
					>
						Any
					</TabItem>
					{treatments.map((treatment) => (
						<TabItem
							tabStyle="transparent"
							key={treatment.id}
							selected={selectedTreatment === treatment}
							onClick={() => setSelectedTreatment(treatment)}
						>
							{treatment.name}
						</TabItem>
					))}
				</Carousel>
			</Hero>

			<div className="container">
				<ClinicsList
					parentFilters={{
						treatment: selectedTreatment,
						destination: destination?.name,
					}}
				/>
			</div>

			<Footer />
		</div>
	);
};

export default DestinationDetail;
