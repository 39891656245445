import React, { useState, useEffect, useCallback } from "react";
import * as Icon from "react-feather";
import { useSearchParams } from "react-router-dom";
import { useGlobal } from "../../contexts/GlobalContext";
import { Clinic, Filters } from "../../types";

interface FilterPanelProps {
	clinics: Clinic[];
	parentFilters?: Filters | null;
	onFiltersChange: (filters: Filters) => void;
	onFiltersOpen: (isOpenFilters: boolean) => void;
	resultsCount: number;
	className?: string;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
	clinics,
	parentFilters,
	onFiltersChange,
	onFiltersOpen,
	resultsCount,
	className,
}) => {
	const availableLanguages = Array.from(
		new Set(clinics.flatMap((clinic) => clinic.languages || []))
	);
	// const availableHighlights = Array.from(
	// 	new Set(clinics.flatMap((clinic) => clinic.highlights || []))
	// );

	const [rating, setRating] = useState<number | null | undefined>(null);
	const [priceRange, setPriceRange] = useState<number | null | undefined>(null);
	const [languages, setLanguages] = useState<string[]>([]);
	const [highlights, setHighlights] = useState<string[]>([]);

	const [searchParams] = useSearchParams();
	const { treatments } = useGlobal();

	const destinationSearch = searchParams.get("destination");
	const treatmentSearch = searchParams.get("treatment");

	const handleFilterChange = useCallback(() => {
		const searchedTreatment = treatments.find(
			(t) => treatmentSearch && t.name === treatmentSearch
		);

		onFiltersChange({
			treatment: searchedTreatment ?? parentFilters?.treatment,
			destination: destinationSearch ?? parentFilters?.destination,
			rating: rating ?? parentFilters?.rating,
			priceRange: priceRange ?? parentFilters?.priceRange,
			languages: languages ?? parentFilters?.languages,
			highlights: highlights ?? parentFilters?.highlights,
		});
	}, [
		rating,
		priceRange,
		languages,
		highlights,
		searchParams,
		parentFilters,
		onFiltersChange,
	]);

	const handleReset = () => {
		setRating(null);
		setPriceRange(null);
		setLanguages([]);
		setHighlights([]);
	};

	const noFiltersSelected =
		!rating &&
		!priceRange &&
		(!languages || languages?.length === 0) &&
		(!highlights || highlights?.length === 0);

	const resultsLabel =
		resultsCount === 1 ? `1 result` : `${resultsCount} results`;

	// Trigger filter update whenever filter state changes
	useEffect(() => {
		handleFilterChange();
	}, [
		rating,
		priceRange,
		languages,
		highlights,
		searchParams,
		parentFilters,
		handleFilterChange,
	]);

	return (
		<aside className={`filters ${className || ""}`}>
			<div className="filters__header margin-bottom--lg">
				<h5 className="filters__count">{resultsLabel}</h5>

				{!noFiltersSelected && (
					<button
						className="button button--xs filters__reset"
						onClick={handleReset}
					>
						Reset
					</button>
				)}
			</div>
			<div className="filters__section filters__section--vertical">
				<label className="filters__section__divider">Clinic rating</label>
				<label className="filter-button">
					<input
						type="checkbox"
						checked={rating === 4}
						onChange={() => setRating(rating === 4 ? null : 4)}
					/>
					<span className="filter-button__label">4 or more</span>
				</label>
				<label className="filter-button">
					<input
						type="checkbox"
						checked={rating === 3}
						onChange={() => setRating(rating === 3 ? null : 3)}
					/>
					<span className="filter-button__label">3 or more</span>
				</label>
				<label className="filter-button">
					<input
						type="checkbox"
						checked={rating === 2}
						onChange={() => setRating(rating === 2 ? null : 2)}
					/>
					<span className="filter-button__label">2 or more</span>
				</label>
			</div>

			<div className="filters__section filters__section--vertical">
				<label className="filters__section__divider">Price range</label>

				<label className="filter-button">
					<input
						type="checkbox"
						checked={priceRange === 10000}
						onChange={() => setPriceRange(priceRange === 10000 ? null : 10000)}
					/>
					<span className="filter-button__label">Up to $10,000</span>
				</label>
				<label className="filter-button">
					<input
						type="checkbox"
						checked={priceRange === 5000}
						onChange={() => setPriceRange(priceRange === 5000 ? null : 5000)}
					/>
					<span className="filter-button__label">Up to $5,000</span>
				</label>
				<label className="filter-button">
					<input
						type="checkbox"
						checked={priceRange === 1000}
						onChange={() => setPriceRange(priceRange === 1000 ? null : 1000)}
					/>
					<span className="filter-button__label">Up to $1,000</span>
				</label>
			</div>

			<div className="filters__section">
				<label className="filters__section__divider">Spoken languages</label>
				{availableLanguages.map((language) => (
					<label className="filter-button" key={language}>
						<input
							type="checkbox"
							checked={languages.includes(language)}
							onChange={(e) =>
								setLanguages((prev) =>
									e.target.checked
										? [...prev, language]
										: prev.filter((l) => l !== language)
								)
							}
						/>
						<span className="filter-button__label">{language}</span>
					</label>
				))}
			</div>
			{/* 
			<div className="filters__section">
				<label className="filters__section__divider">Highlights</label>
				{availableHighlights.map((highlight) => (
					<label className="filter-button" key={highlight}>
						<input
							type="checkbox"
							checked={highlights.includes(highlight)}
							onChange={(e) =>
								setHighlights((prev) =>
									e.target.checked
										? [...prev, highlight]
										: prev.filter((h) => h !== highlight)
								)
							}
						/>
						<span className="filter-button__label">{highlight}</span>
					</label>
				))}
			</div>
      */}
			<button
				className="button button--icon button--sm filters__close"
				onClick={() => onFiltersOpen(false)}
			>
				<Icon.X size={20} />
			</button>

			<button
				className="button button--primary filters__apply"
				onClick={() => onFiltersOpen(false)}
			>
				Apply filters
			</button>
		</aside>
	);
};

export default FilterPanel;
