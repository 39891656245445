import React from "react";

type TabItemProps = {
	selected?: boolean;
	tabStyle?: "tag" | "opaque" | "transparent";
	children?: React.ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const TabItem: React.FC<TabItemProps> = ({
	selected = false,
	tabStyle = "card",
	children,
	onClick = () => {},
}) => {
	return (
		<button
			className={[
				"tab",
				tabStyle === "tag" && "tab--tag",
				tabStyle === "opaque" && "tab--card",
				tabStyle === "transparent" && "tab--card tab--transparent",
				selected && "tab--selected",
			]
				.filter(Boolean)
				.join(" ")}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default TabItem;
