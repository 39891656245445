import React, { useRef } from "react";
import * as Icon from "react-feather";

type ImageGridProps = {
	featured_image?: string;
	images?: string[];
	className?: string;
};

const ImageGrid: React.FC<ImageGridProps> = ({
	featured_image,
	images,
	className,
}) => {
	const modalRef = useRef<HTMLDialogElement>(null);

	const showModal = () => {
		modalRef.current?.showModal();
	};

	const closeModal = () => {
		modalRef.current?.close();
	};

	return (
		<div className={`image-grid ${className || ""}`}>
			<div className="image-grid__inner container">
				<div className="image-grid__featured">
					<img src={featured_image} alt="" className="image-grid__image" />
					<button
						className="button button--floating button--sm"
						onClick={showModal}
					>
						<Icon.Plus size={20} />
						Show all photos
					</button>
				</div>
				{images && images.length > 0 && (
					<div className="image-grid__images">
						{images?.map((image, index) => (
							<img
								src={image}
								key={index}
								alt=""
								className="image-grid__image"
							/>
						))}
					</div>
				)}
			</div>
			<dialog className="image-grid__modal" ref={modalRef}>
				<div className="image-grid__modal__images">
					<img src={featured_image} alt="" className="image-grid__image" />
					{images?.map((image, index) => (
						<img
							src={image}
							key={"zoom-" + index}
							alt=""
							className="image-grid__image"
						/>
					))}
				</div>
				<button
					className="button button--floating button--sm button--icon image-grid__modal__close"
					onClick={closeModal}
				>
					<Icon.X size={20} />
				</button>
			</dialog>
		</div>
	);
};

export default ImageGrid;
