import React, { useState, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Treatment } from "../../../types";
import TreatmentCard from "../../Cards/TreatmentCard/TreatmentCard";

type TreatmentsGridProps = {
	treatments: Treatment[];
	resultsLimit?: number;
	className?: string;
};

const TreatmentsGrid: React.FC<TreatmentsGridProps> = ({
	treatments,
	resultsLimit = 8,
	className,
}) => {
	const [visibleCount, setVisibleCount] = useState(resultsLimit);
	const nodeRefs = useRef<(HTMLAnchorElement | null)[]>([]); // For CSSTransition to work

	const handleShowMore = () => {
		setVisibleCount((prevCount) => prevCount + 4);
	};

	return (
		<div className={`treatments-grid ${className || ""}`}>
			<TransitionGroup className="treatments-grid__inner">
				{treatments.slice(0, visibleCount).map((treatment, index) => {
					// Ensure each ref corresponds to the correct treatment
					if (!nodeRefs.current[index]) {
						nodeRefs.current[index] = null;
					}

					return (
						<CSSTransition
							nodeRef={{ current: nodeRefs.current[index] }}
							key={index}
							timeout={400}
							classNames="card-transition"
						>
							<TreatmentCard
								ref={(el) => {
									nodeRefs.current[index] = el;
								}}
								treatment={treatment}
							/>
						</CSSTransition>
					);
				})}
			</TransitionGroup>

			{visibleCount < treatments.length && (
				<button className="button" onClick={handleShowMore}>
					Show more
				</button>
			)}
		</div>
	);
};

export default TreatmentsGrid;
