import { Link } from "react-router-dom";
import Logo from "../../components/Logo/Logo";

const Footer = (): React.ReactElement => {
	return (
		<footer className="footer">
			<div className="footer__inner">
				<Link to="/" className="footer__logo">
					<Logo vertical />
				</Link>

				<ul className="footer__list list">
					<li>
						<Link to="/" className="link">
							Terms of service
						</Link>
					</li>
					<li>
						<Link to="/" className="link">
							Privacy policy
						</Link>
					</li>
					<li className="footer__disclaimer">
						© TripMD 2025. All rights reserved.
					</li>
				</ul>
			</div>
		</footer>
	);
};

export default Footer;
