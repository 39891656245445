import React from "react";
import { Link } from "react-router-dom";
import { Country } from "../../../types";
import Badge from "../../Badge/Badge";

type DestinationCardProps = {
	country: Country;
};

const DestinationCard: React.FC<DestinationCardProps> = ({ country }) => {
	const { name, region, image, popular_specialities } = country;

	return (
		<Link
			to={"/destinations/" + country.id}
			className="card card--interactive destination-card"
			style={{
				backgroundImage: image ? `url(${image})` : undefined,
			}}
		>
			<span className="destination-card__top">
				<span className="paragraph--sm">{region}</span>
				<span className="h4">{name}</span>
			</span>
			<span className="destination-card__bottom">
				{popular_specialities?.map((speciality) => (
					<Badge key={speciality.id} color={speciality.color}>
						{speciality.name}
					</Badge>
				))}
			</span>
		</Link>
	);
};

export default DestinationCard;
