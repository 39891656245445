import React from "react";

type BadgeProps = {
	color?: string;
	size?: "sm" | "md" | "lg";
	children?: React.ReactNode;
};

const Badge: React.FC<BadgeProps> = ({ color, size = "md", children }) => {
	return (
		<span className={`badge ${size === "sm" && "badge--sm"}`}>
			<span
				className="badge__dot"
				style={color ? { backgroundColor: color } : undefined}
			/>
			{children}
		</span>
	);
};

export default Badge;
