import React from "react";

type BannerProps = {
	title?: string;
	body?: string;
	image?: string;
	className?: string;
};

const Banner: React.FC<BannerProps> = ({
	title,
	body,
	image,
	className = "",
}) => {
	return (
		<section className="banner">
			<div className="container">
				<div
					className="banner__inner"
					style={{
						backgroundImage: image ? `url(${image})` : undefined,
					}}
				>
					<h2 className="banner__title">{title}</h2>
					<p className="banner__body">{body} </p>
				</div>
			</div>
		</section>
	);
};

export default Banner;
