const backend = process.env.REACT_APP_API_URL;

export function getHeaders(data) {
	const accessToken = JSON.parse(
		localStorage.getItem("userTokens")
	)?.access_token;

	var myHeaders = new Headers();

	if (accessToken) {
		if (data instanceof FormData) {
			myHeaders.append("Authorization", `Bearer ${accessToken}`);
			// myHeaders.append("Content-Type", "multipart/form-data");
			return myHeaders;
		} else {
			myHeaders.append("Authorization", `Bearer ${accessToken}`);
			myHeaders.append("Content-Type", "application/json");
			return myHeaders;
		}
	} else {
		return myHeaders;
	}
}

export function apiFetch(method, path, data, auth, headersObj) {
	const url = backend + path;
	let headers = auth
		? getHeaders(data)
		: {
				"Content-Type":
					data instanceof FormData ? "multipart/form-data" : "application/json",
		  };
	if (headersObj) {
		headers = { ...headers, ...headersObj };
	}
	if (headers["Content-Type"] === "application/json" && data) {
		data = JSON.stringify(data);
	}
	const options = {
		method,
		headers,
		...(data ? { body: data } : {}),
	};

	return fetch(url, options)
		.then((response) => {
			if (response.ok) {
				return response;
			} else if (
				response.status === 401 &&
				window.location.pathname !== "/sign-in"
			) {
				window.location.href = "/sign-in";
				localStorage.removeItem("userTokens");
			} else if (response.status === 403) {
				response?.text().then((text) => {
					const tempErrors = JSON.parse(text);
					console.log("tempErrors", tempErrors.detail);
					if (tempErrors.detail === "User is not authenticated") {
						localStorage.removeItem("userTokens");
						window.location.href = "/sign-in";
					} else {
						window.location.href = "/error";
					}
				});
			}
			return response;
		})
		.catch((error) => {
			console.error(`Failed to fetch ${url}`, error);
			throw error;
		});
}
