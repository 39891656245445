import React, { forwardRef } from "react";
import * as Icon from "react-feather";
import { useNavigate } from "react-router-dom";
import { Clinic, Treatment } from "../../../types";
import Badge from "../../Badge/Badge";
import CompareButton from "../../CompareButton/CompareButton";
import Rating from "../../Rating/Rating";
import { useGlobal } from "../../../contexts/GlobalContext";
import { getTreatmentById } from "../../../util/clinicUtil";

type ClinicCardProps = {
	clinic: Clinic;
	treatment?: Treatment | null;
	compact?: boolean;
	className?: string;
};

const ClinicCard = forwardRef<HTMLDivElement, ClinicCardProps>(
	({ clinic, treatment, compact = false, className }, ref) => {
		const { treatments } = useGlobal();
		const navigate = useNavigate();
		const maxVisibleTreatments = 6;

		const matchingTreatment = treatment
			? clinic.treatments?.find((t) => t.treatment === treatment.id)
			: null;

		const handleClick = () => {
			const params = treatment
				? `?treatment=${encodeURIComponent(treatment.slug)}`
				: "";
			navigate("/clinics/" + clinic.id + params);
		};

		return (
			<div
				ref={ref}
				className={`clinic-card ${className || ""} ${
					compact ? "clinic-card--compact" : ""
				}`}
			>
				<button
					className="clinic-card__inner card card--interactive"
					onClick={() => handleClick()}
				>
					<span className="clinic-card__leading">
						<img
							className="clinic-card__image"
							src={clinic.featured_image}
							alt=""
						/>
						<span className="clinic-card__image__head">
							{/* {location && (
							<span className="paragraph--sm text--medium">
								{location.city + ", " + location.country}
							</span>
						)} */}
							<span className="h5">{clinic.name}</span>

							{clinic.tagline && (
								<span className="paragraph--sm text--medium">
									{clinic.tagline}
								</span>
							)}
						</span>
					</span>
					<span className="clinic-card__main paragraph--sm">
						<span className="clinic-card__main__head">
							{clinic.location && (
								<span className="text--secondary text--medium">
									{clinic.location.city + ", " + clinic.location.country}
								</span>
							)}
							<span className="clinic-card__title h4">{clinic.name}</span>
						</span>
						{clinic.featuredHighlights && (
							<span className="clinic-card__highlights text--medium">
								{clinic.featuredHighlights.map((highlight, index) => (
									<span className="clinic-card__highlight" key={index}>
										<Icon.ThumbsUp size={16} />
										{highlight.title}
									</span>
								))}
							</span>
						)}

						<span className="clinic-card__accreditations">
							{clinic.languages && (
								<span className="clinic-card__accreditation">
									<Icon.MessageSquare size={16} />
									{clinic.languages.join(", ")}
								</span>
							)}
							{clinic.accreditations && (
								<span className="clinic-card__accreditation">
									<Icon.Award size={16} />
									{clinic.accreditations.join(", ")}
								</span>
							)}

							{clinic.trades && (
								<span className="clinic-card__accreditation">
									<Icon.Tag size={16} />
									{clinic.trades}
								</span>
							)}

							{clinic.website && (
								<span className="clinic-card__accreditation">
									<Icon.Globe size={16} />
									{clinic.website}
								</span>
							)}
						</span>
					</span>

					<span className="clinic-card__trailing">
						<Rating
							rating={clinic.rating}
							ratingCount={clinic.ratingCount || 0}
							compact
							vertical
						/>
						{!treatments ||
							(treatments.length === 0 && (
								<span className="clinic-card__treatments-list">
									<Badge size="sm">No treatments</Badge>
								</span>
							))}

						{matchingTreatment ? (
							<span className="clinic-card__treatment-info">
								<span className="clinic-card__value">
									<span className="paragraph--xs text--secondary">
										Recovery
									</span>
									<span className="paragraph text--medium">
										{matchingTreatment.recovery_days?.toFixed(0) + " days" ||
											"Not specified"}
									</span>
								</span>
								{matchingTreatment.starting_price !== undefined ? (
									<span className="clinic-card__value">
										<span className="paragraph--xs text--secondary">
											Starting at
										</span>
										<span className="paragraph text--medium">
											${matchingTreatment.starting_price.toFixed(2)}
										</span>
									</span>
								) : (
									<span className="clinic-card__value">
										<span className="paragraph text--medium">
											No price available
										</span>
									</span>
								)}
							</span>
						) : (
							<span className="clinic-card__treatments-list">
								{clinic.treatments && clinic.treatments.length > 0 ? (
									clinic.treatments.slice(0, maxVisibleTreatments).map((t) => (
										<Badge
											color={
												getTreatmentById(t.treatment, treatments)?.speciality
													?.color
											}
											size="sm"
											key={t.id}
										>
											{getTreatmentById(t.treatment, treatments)?.name}
										</Badge>
									))
								) : (
									<></>
								)}

								{clinic.treatments &&
									clinic.treatments.length > maxVisibleTreatments && (
										<span className="paragraph--sm text--secondary">
											+ {clinic.treatments.length - maxVisibleTreatments} more
										</span>
									)}
							</span>
						)}
					</span>
				</button>
				<span className="clinic-card__buttons">
					<CompareButton clinic={clinic} className="button--floating" />
					{/* <button className="button button--floating button--sm button--icon">
						<Icon.Heart size={20} />
					</button> */}
				</span>
			</div>
		);
	}
);

export default ClinicCard;
