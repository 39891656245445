import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useComparison } from "../../contexts/ComparisonContext";
import { useRef } from "react";

const ComparisonCart = (): React.ReactElement => {
	const { comparisonCart, notification } = useComparison();
	const nodeRef = useRef<HTMLDivElement>(null); // For CSSTransition to work

	return (
		<div className="navigation__action comparison-cart">
			<Link
				to="/comparison"
				className={`button button--sm button--icon comparison-cart__button ${
					comparisonCart.length > 0 ? "comparison-cart--active" : ""
				}`}
			>
				<span className="comparison-cart__count">{comparisonCart.length}</span>
				<Icon.Columns size={20} />
			</Link>

			<TransitionGroup>
				{notification && (
					<CSSTransition
						nodeRef={nodeRef}
						timeout={600}
						classNames="toast-transition"
					>
						<div ref={nodeRef} className="comparison-cart__toast">
							<img
								src={notification.featured_image}
								className="comparison-cart__toast__image"
								alt=""
							/>
							<div className="comparison-cart__toast__content">
								<p className="paragraph--xs comparison-cart__toast__subline margin-bottom--xxs">
									Added to comparison
								</p>
								<p className="paragraph--sm margin-bottom--none">
									{notification.name}
								</p>
							</div>
						</div>
					</CSSTransition>
				)}
			</TransitionGroup>
		</div>
	);
};

export default ComparisonCart;
