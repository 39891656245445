import * as Icon from "react-feather";

const ValueProposition = (): React.ReactElement => {
	return (
		<section className="value-prop">
			<div className="value-prop__container container">
				<h2 className="value-prop__title margin-bottom--xxl">
					Your Trusted Partner for Medical Care Abroad
				</h2>
				<p className="value-prop__subtitle lead margin-bottom--xxxl lead">
					We make finding the right treatment abroad easy. Compare top clinics
					and book with confidence.
				</p>
				<div className="value-prop__cards">
					<div className="card value-prop__card">
						<Icon.Award size={32} />
						<div className="value-prop__card__content">
							<h5 className="margin-bottom--xs">Trusted Clinics</h5>
							<p className="">
								We work with internationally accredited hospitals and
								experienced medical teams.
							</p>
						</div>
					</div>
					<div className="card value-prop__card">
						<Icon.Globe size={32} />
						<div className="value-prop__card__content">
							<h5 className="margin-bottom--xs">Global Destinations</h5>
							<p className="">
								Choose from top healthcare destinations tailored to your
								treatment needs.
							</p>
						</div>
					</div>
					<div className="card value-prop__card">
						<Icon.Search size={32} />
						<div className="value-prop__card__content">
							<h5 className="margin-bottom--xs">Personalized Search</h5>
							<p className="">
								Find treatments, destinations, and clinics that fit your health
								and travel preferences.
							</p>
						</div>
					</div>
					<div className="card value-prop__card">
						<Icon.DollarSign size={32} />
						<div className="value-prop__card__content">
							<h5 className="margin-bottom--xs">Transparent Pricing</h5>
							<p className="">
								Compare costs upfront with no hidden fees for peace of mind.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ValueProposition;
