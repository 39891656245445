import React from "react";

type RatingProps = {
	rating?: number;
	ratingCount?: number;
	source?: string;
	compact?: boolean;
	vertical?: boolean;
};

const Rating: React.FC<RatingProps> = ({
	rating,
	ratingCount,
	source,
	compact = false,
	vertical = false,
}) => {
	const getRatingKeyword = (rating?: number): string => {
		if (!rating) return "No rating";
		if (rating >= 4.5) return "Excellent";
		if (rating >= 3.5) return "Good";
		if (rating >= 2.5) return "Average";
		if (rating >= 1.5) return "Fair";
		return "Poor";
	};

	const getRatingClassName = (rating?: number): string => {
		if (!rating) return "rating--mediocre";
		if (rating >= 4.5) return "rating--positive";
		if (rating >= 3.5) return "rating--positive";
		if (rating >= 2.5) return "rating--mediocre";
		if (rating >= 1.5) return "rating--mediocre";
		return "rating--negative";
	};

	return (
		<span
			className={`rating rating--sm ${compact ? "rating--compact" : ""} ${
				vertical ? "rating--vertical" : ""
			} ${getRatingClassName(rating)}
			`}
		>
			{rating ? (
				<span className="rating__box">{rating.toFixed(1)}</span>
			) : (
				<span className="rating__box">n/a</span>
			)}
			<span className="rating__summary">
				<span className="rating__keyword paragraph text--medium">
					{getRatingKeyword(rating)}
				</span>
				{ratingCount && ratingCount > 0 ? (
					<span className="rating__count paragraph--xs text--secondary">
						{!compact && <span>Based on </span>}
						<span>{ratingCount} reviews</span>
						{source && <span> on {source}</span>}
					</span>
				) : (
					<></>
				)}
			</span>
		</span>
	);
};

export default Rating;
