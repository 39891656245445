import { SortMode } from "../components/ClinicsListToolbar/ClinicsListToolbar";
import { Clinic, Filters, Treatment } from "../types";

export const calculateAveragePrice = (prices: number[]): number => {
	if (prices.length === 0) return Infinity;
	const total = prices.reduce((sum, price) => sum + price, 0);
	return total / prices.length;
};

export const sortClinics = (
	clinics: Clinic[],
	sortOption: SortMode,
	treatment?: Treatment | null
): Clinic[] => {
	const sorted = [...clinics];

	if (sortOption === SortMode.Rating) {
		sorted.sort((a, b) => (b.rating || 0) - (a.rating || 0));
	} else if (sortOption === SortMode.Price) {
		sorted.sort((a, b) => {
			if (treatment !== undefined) {
				const priceA =
					a.treatments?.find((t) => t.treatment === treatment?.id)
						?.starting_price || Infinity;
				const priceB =
					b.treatments?.find((t) => t.treatment === treatment?.id)
						?.starting_price || Infinity;
				return priceA - priceB;
			} else {
				const avgA = calculateAveragePrice(
					a.treatments?.map((t) => t.starting_price || Infinity) || []
				);
				const avgB = calculateAveragePrice(
					b.treatments?.map((t) => t.starting_price || Infinity) || []
				);
				return avgA - avgB;
			}
		});
	}

	return sorted;
};

export const filterClinics = (
	clinics: Clinic[],
	filters: Filters | null
): Clinic[] => {
	return clinics.filter((clinic) => {
		if (filters == null) {
			return true;
		}

		const matchesTreatment = filters.treatment
			? clinic.treatments?.some((t) => t.treatment === filters.treatment?.id)
			: true;

		const matchesDestination = filters.destination
			? clinic.location?.country === filters.destination ||
			  clinic.location?.city === filters.destination
			: true;

		const matchesRating = filters.rating
			? clinic.rating && clinic.rating >= filters.rating
			: true;

		const matchesPriceRange = filters.priceRange
			? clinic.treatments?.some(
					(t) => (t.starting_price || Infinity) <= (filters.priceRange || 0)
			  )
			: true;

		const matchesLanguages = filters.languages
			? filters.languages.length === 0 ||
			  filters.languages.some((lang) => clinic.languages?.includes(lang))
			: true;

		const matchesHighlights = filters.highlights
			? filters.highlights.length === 0 ||
			  filters.highlights.some((highlight) =>
					clinic.highlights?.includes(highlight)
			  )
			: true;

		return (
			matchesTreatment &&
			matchesDestination &&
			matchesRating &&
			matchesPriceRange &&
			matchesLanguages &&
			matchesHighlights
		);
	});
};

export const getTreatmentById = (
	id: number | undefined,
	treatments: Treatment[]
): Treatment => {
	return (
		treatments.find((t) => t.id === id) ?? {
			id: -1,
			slug: "Other",
			name: "Other",
		}
	);
};
